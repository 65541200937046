
.project-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0 16px;
    margin: 16px;
    width: 20%; 
    text-align: left;
  }
.project-card h3 {
    margin: 0.5em 0; /* Reduces the space around the heading */
    font-size: 1.1em; /* Adjust base font size */
    height: 3em; /* Set a specific height to accommodate two lines of text */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; /* Add ellipsis to text that can't fit */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Show ellipsis after 2 lines */
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .project-card img {
    max-width: 100%;
    border-radius: 8px;
    height: auto;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .project-info{
    display: flex;
    flex-direction: column;
  }
  .project-info p strong {
    display: inline-block;
    min-width: 50%; 
    max-width: 15%;
  }
  .project-info p {
    margin: 0.5em 0; /* Reduces the space around the paragraphs */
  }
/* Vote Share Section */
.vote-share-section {
  bottom: 10px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  }

/* share button */

.share-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.3em;
}
.share-button svg{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.share-button button {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-button button:hover {
  background-color: #00597a;
  color: #fff;
}

.share-dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  width: max-content; 
  z-index: 100; 
  display: flex; 
  opacity: 1; 
  visibility: visible; 
  flex-direction: row;
}

.share-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.share-dropdown-content a:hover {
  background-color: #ddd;
}

.share-dropdown-content img {
  width: 20px;
  height: 20px;
}
/* end share button */
/* Vote Count */
.vote-count {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.3em;
}

.upvote {
  color: #0095d5;
  margin-left: 10px;
}

.downvote {
  color: #a45104;
  margin-right: 10px;
}

.upvote:hover {
  color: #33b4e3;
}

.downvote:hover {
  color: #f60505;
}

.vote-count span {
  margin-left: 10px;
}

@media screen and (max-width: 1112px) {
  .project-card {
    width: 90%; /* Adjust the width for smaller screens */
    margin: 16px auto; /* Center the card on smaller screens */
  }
}

@media screen and (min-width: 1112px) {
  .project-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}