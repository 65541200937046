/* Updated AboutPage.css */

.about-container {
  width: 80%;
  margin: auto;
  padding: 40px;
  margin-bottom: 10%;
}
.about-container h1, .about-container h2 {
  color: #00597a;
}
.about-container h1{
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 3em;
}
.about-container h2{
  font-size: 2em;
  margin-top: 2em;
}

.about-section {
  display: flex;
  align-items: center; /* Ensures image is aligned with text vertically */
  justify-content: center; /* Centers the content horizontally */
  margin-bottom: 50px;
}

.about-section-image {
  display: flex;
  align-items: center; /* Center align the image within its container */
  justify-content: center; /* Center the image horizontally within its container */
}

.about-section-image img {
  max-width: 100%; /* Ensures the image is responsive and does not overflow its container */
  height: auto; /* Maintains the aspect ratio of the image */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.about-section-text, .about-section-image {
  flex: 1;
}

.about-section-text {
  padding: 0 20px;
}
.about-section-text h2 {
 margin: auto;
}
.about-section-text p {
  line-height: 1.6;
  text-align: justify;
  font-size: 1.6em;
}

.about-section-image img{
  width: 100%;
  height: auto;
}

.reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1112px) {
  .about-container {
    width: 95%;
    padding: 10px;
    margin:auto;
  }
  .about-section {
    flex-direction: column;
  }
  .reverse {
    flex-direction: column;
  }
}
