.faq-container {
    width: 60%;
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10%;

  }
  .faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
  }

  .faq-container h3 {
    font-size: 1.6em;
  }
  .faqs {
    list-style-type: none;
    padding: 0;
    font-size: 1.5em;
  }
  
  .faq {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  
  .faq:last-child {
    border-bottom: none;
  }
  .faq-question {
    color: #0056b3;
  }
  
  .faq-answer {
    display: none;
    padding-top: 10px;
  }
  
  .faq.active .faq-answer {
    display: block;
  }
  @media screen and (max-width: 1112px) {
    .faq-container {
      width: 95%;
      padding: 10px;
      margin: auto;
    }
  }