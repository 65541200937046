.project-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 1.4em;
    margin-bottom: 10%;
  }
  .project-list {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  /* Loading container styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

/* SVG styles, if needed, can be adjusted or expanded */
.svg-loading {
  margin: auto;
  background: none;
  display: block;
  width: 50px;
  height: 50px;
}
  @media screen and (max-width: 1112px) {
    .project-list-page {
      width: 100%;
      padding: 10px;
      margin: auto;
    }
  }