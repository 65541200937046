.homepage {
  font-family: 'Poppins', sans-serif;
  color: #101010;
  width: 100%;
}
.content-and-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
  width: 80%;
  margin: auto;
  padding-top: 3%;
}

.content-section {
  flex: 1;
  text-align: center;
}
.content-section h1{
  font-size: 2.4em;
}
.content-section p {
  font-size: 1.4em;
  font-style: italic;
}
.image-section {
  flex: 1;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-image: url('../../images/home.jpg');
  border-radius: 10px;
}

.key-points {
  padding: 12px 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; 
}

.key-points ul {
  list-style-type: none;
  padding: 0 20px;
}

.key-points li {
  font-size: 2em;
  line-height: 2;
  margin-bottom: 16px;
  position: relative; /* Prepares for custom bullet */
  padding-left: 40px; /* Makes space for custom bullet */
  text-align: left; /* Ensures text is aligned left */
  color: #101010; /* Adjust color to fit your design, if needed */
}

.key-points li::before {
  content: '•'; /* Simple bullet point */
  color: #a7a6a6; /* Gold color for bullet points for visibility */
  font-size: 2em; /* Larger bullet point size */
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0%, -25%); /* Adjusts bullet position */
}

.action-links {
  padding: 10px 0 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-links a {
  background-color: #0057b5;
  border-radius: 15px;
  color: #e7e7e7;
  padding: 12px 5px;
  margin: 0 15px;
  text-decoration: none;
  font-size: 2.2em;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 180px;
}

.action-links a:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 1112px) {
  .homepage{
    width: 95%;
    padding: 10px;
    margin:auto;
    color: #e2e5ea;
  }
  .hero-section {
    padding: 0px;
    }
  .key-points {
    width: 100%;
    padding: 0px;
  }
  .key-points li {
    font-size: 1.8em;
    color: #e2e5ea;
  } 
  .content-and-image {
    flex-direction: column;
    padding: 0px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.9)),
    url('../../images/home.jpg') no-repeat center center/cover; 
    border-radius: 10px;
    width: 100%;
    font-size: 0.8em;
  }
  .image-section {
    background: none;
  }
  .action-links a{
    font-size: 1.8em;
    /* color: #e2e5ea; */
    /* background-color: #0056b3; */

    }
}