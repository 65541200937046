/* ProjectDetailPage.css */

.project-detail {
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 60%;
    font-size: 1.6em;
    margin-bottom: 10%;
  }
  .project-contact a{
    color: inherit; /* This makes the link use the text color of its parent, removing the default blue */
    text-decoration: none; /* This removes the underline */
    cursor: pointer;
  }
  .project-detail img {
    width: 50%;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
  }
  .project-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.4em;
  }
  
  .project-meta, .project-description, .project-contact, .project-stats, .project-status {
    margin-bottom: 20px;
  }
  .project-contact {
    margin-bottom: 40px;
  }
  .project-meta p, .project-description p, .project-contact p, .project-stats p, .project-status p {
    margin: 10px 0;
    line-height: 1.6;
  }
  .project-detail p strong {
    display: inline-block;
    min-width: 25%; 
    max-width: 50%;
  }
  strong {
    color: #0056b3;
  }
  .detail-vote-share-section {
    bottom: 10px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #ddd;
    margin-top: 10px;
    margin-bottom: 5%;
    padding-bottom: 50px;
    }
  @media screen and (max-width: 1112px) {
    .project-detail{
      width: 95%;
      padding: 10px;
      margin: auto;
    }
  }