/* App.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  /* background-image: radial-gradient(circle at center, rgb(216 216 216 / 80%), white); */
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #f4f4f4;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}
.footer-contact-email a{
  margin-top: 15px;
  color: inherit; /* This makes the link use the text color of its parent, removing the default blue */
  text-decoration: none; /* This removes the underline */
  cursor: pointer;
}
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
}

.error-message {
  color: red;
  font-size: 1.6em;
  margin-top: 15px;
}

@media (max-width: 1112px) {
  .sidebar {
    position: relative;
  }
  .footer {
    position: relative;
    width: auto;
  }
}

  