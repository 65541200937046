.tab {
    margin-top: 20px;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}

.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.tab-button {
    background-color: #f9fafb;
    border: 1px solid #ddd;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    color: #056baf;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
}

.tab-button.active {
    background-color: #056baf;
    color: white;
}

.tab-tab-content {
    background-color: #f9fafb;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Styles for PendingProjects Component */
.pending-projects-container, .active-projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pending-project , .active-project{
    background-color: white;
    padding: 15px;
    margin-bottom: 10px;
    width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.tab-project-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.project-info>div {
    margin-bottom: 5px;
}

.project-title,
.project-store,
.project-price,
.project-contact {
    font-size: 1em;
    color: #313131;
}

.project-image img {
    max-width: 400px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.project-actions button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.project-actions button:hover {
    opacity: 0.8;
}

.tab-error-message {
    color: red;
    margin-top: 10px;
}

.project-updated {
    background-color: #e0f7fa;
    /* A subtle color to indicate update, adjust as per your UI */
}

.tab-project-actions-reject {
    background-color: #f4b352;
    color: white;
}

.tab-project-actions-reject:hover {
    background-color: #e7900e;
    opacity: 0.8;
}

.tab-project-actions-approve {
    background-color: #4caf50;
    color: white;
}

.tab-project-actions-approve:hover {
    background-color: #3e8e41;
    opacity: 0.8;
}

/* Responsive design for smaller screens */
@media screen and (max-width: 1112px) {
    .pending-projects-container .pending-project {
        width: 100%;
    }
}