/* Add Project Form Styles */
.project-form-container{
width: 60%;
margin-bottom: 10%;

}
.add-project-form {
  font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-project-form input[type="text"],
  .add-project-form input[type="number"],
  .add-project-form input[type="date"],
  .add-project-form input[type="email"],
  .add-project-form select,
  .add-project-form button {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
  }
  .add-project-form select {
    width: 97%;
  }
  
  .add-project-form label {
    display: block;
    margin-bottom: 10px;
    padding-left: 10px;
    align-self: flex-start;
    font-family: 'Poppins', sans-serif;

  }
  
  .add-project-form button {
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    width: auto;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.6em;
  }
  
  .add-project-form button:hover {
    background-color: #003d7a;
  }
  .add-project-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100px; /* Adjust height as needed */
    font-family: 'Poppins', sans-serif;

  }
  
  .section.location {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 94.5%;
  }

  .section h3 {
    margin-top: 0;
  }
  @media screen and (max-width: 1112px) {
    .project-form-container {
      width: 95%;
      padding: 10px;
      margin: auto;
    }
  }