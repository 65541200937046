.dashboard-container {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10%;
}

.dashboard-user-info {
    background-color: #f9fafb;
    /* Light blue background */
    border-radius: 10px;
    padding-left: 2%;
    margin: 2%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
}

.dashboard-user-info p {
    font-style: italic;
    font-weight: bold;
    font-size: 1.8em;
    color: #056baf;
    margin: 10px 0;
}

.dashboard-user-info span {
    margin-left: 10px;
    /* Adjust this value as needed to create more space */
    font-size: 1.2em;
    font-weight: bold;
    color: #313131;
    font-style: normal;
    /* Dark blue for emphasis */
}

.dashboard-header {
    font-size: 2em;
    color: #00597a;
    margin-bottom: 20px;
    padding-top: 3%
}

.dashboard-table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px 0;
    font: 1.4em 'Poppins', sans-serif;
}

.dashboard-table th,
.dashboard-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.dashboard-table td a {
    color: #00597a;
    /* Dark blue to match your color scheme */
    text-decoration: none;
    /* Optional: Removes underline from links */
}

.dashboard-table td a:hover {
    text-decoration: underline;
    /* Optional: Adds underline on hover for better user interaction */
}

.dashboard-table th {
    background-color: #00597a;
    color: #fafafa;
}

.dashboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.dashboard-table td img {
    width: 20%;
    height: auto;
    border-radius: 5px;
    /* Rounded corners for images */
}

@media screen and (max-width: 1112px) {
    .dashboard-container {
        width: 95%;
        padding: 10px;
        margin: auto;
    }

    .dashboard-header {
        font-size: 1.5em;
        /* Adjust header font size for smaller screens */
        text-align: center;
        /* Center the header text */
        padding: 10px 0;
        /* Adjust padding to reduce space */
    }

    .dashboard-user-info {
        width: 100%;
        /* Make user info take full width */
        padding: 10px;
        /* Adjust padding for smaller screens */
        align-items: center;
        /* Align items in the center for smaller screens */
        text-align: center;
        /* Center text for user info */
    }

    .dashboard-user-info p {
        font-size: 1.2em;
        /* Adjust font size for smaller screens */
    }

    .dashboard-table {
        font-size: 0.8em;
        /* Reduce table font size for readability */
    }

    .dashboard-table td img {
        width: 50px;
        /* Adjust image width in table cells */
        height: auto;
        /* Keep aspect ratio */
    }

    .tab-buttons {
        flex-direction: column;
        /* Stack tab buttons vertically on small screens */
    }
}
