@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap);
html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: 'Lato', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header Styles */


.logo img {
  width: 25px; /* Make the image responsive */
  height: auto; /* Maintain aspect ratio */
}

.breadcrumb ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center; /* Align items vertically */
}

.breadcrumb li {
  padding: 0 10px; /* Adjust based on your design */
}

/* Optional: Adjust this to ensure the logo and breadcrumb align perfectly */
.logo, .breadcrumb {
  display: flex;
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Align children vertically */
}


.header {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px 20px;
    font-size: 1.6em;
  }
  
  .logo {
    padding-right: 5em;
    padding-top: 0.4em;
    max-width: 100%;
    /* width: 3%; */
  }
  
  .user-welcome-text{
    color: #007bff;
  }
  .user-welcome-text:hover, .user-welcome-active:hover{
    color: #0056b3;
  }
  .user-welcome-active{
    color: #985050;
  }
  .breadcrumb ul {
    list-style: none;
    display: flex;
    font-weight: bold;
  }
  
  .breadcrumb ul li {
    margin-right: 20px;
  }
  
  .breadcrumb ul li a.active {
    color: #985050; /* Active link color, adjust as needed */
  }
  
  .breadcrumb ul li a {
    color: #007bff; /* New link color */
    text-decoration: none;
  }
  
  .breadcrumb ul li a:hover {
    color: #0056b3; /* Hover color */
  }
  
 

.search-bar {
  display: flex; /* Use flexbox for search bar */
  flex-grow: 1; /* Allow search bar to take up remaining space */
  justify-content: flex-end; 
}

.search-bar input {
  flex-grow: 1; /* Allow input to take up available space */
  max-width: 200px; /* Set a max width for the input field */
  width: 150px;
  background-color: #f9f9f9;
  border: 1px solid #00597a;
  border-radius: 10px;
  color: #00597a;
  font-size: 0.7em;
  font-weight: bold;
  margin: 5px;
  padding: 10px 20px;
  transition: background-color 0.3s;
}

.search-bar input::placeholder {
  font-weight: bold;
  opacity: 0.8;
  color: #0056b3;
}

  
  .submenu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .submenu li a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .submenu li a:hover {
    background-color: #f1f1f1;
  }
  
  @media screen and (max-width: 1112px) {
    .header {
      flex-direction: column;
      padding: 10px;
    }
  
    .logo {
      padding-right: 0;
      width: 100%; /* Adjust based on your preference */
      display: flex;
      justify-content: center;
    }
  
    .logo img {
      width: 25px; /* Adjust based on your preference */
    }
  
    /* .breadcrumb ul {
      flex-direction: column;
      align-items: center;
    }
  
    .breadcrumb ul li {
      margin: 5px 0; 
    } */
  
    .breadcrumb ul {
      flex-direction: row; /* Change to row for a horizontal layout */
      flex-wrap: wrap; /* Allow items to wrap */
      justify-content: center; /* Center items horizontally */
    }
  
    .breadcrumb ul li {
      margin: 5px; /* Adjust spacing */
      flex: 1 0 21%; /* Adjust basis and grow as needed, modify the percentage based on preference */
      text-align: center; /* Center text within each link */
    }
  
    .search-bar input {
      width: 90%; /* Adjust input width for smaller screens */
    }
  }
  

.project-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 0 16px;
    margin: 16px;
    width: 20%; 
    text-align: left;
  }
.project-card h3 {
    margin: 0.5em 0; /* Reduces the space around the heading */
    font-size: 1.1em; /* Adjust base font size */
    height: 3em; /* Set a specific height to accommodate two lines of text */
    overflow: hidden; /* Prevent overflow */
    text-overflow: ellipsis; /* Add ellipsis to text that can't fit */
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Show ellipsis after 2 lines */
    -webkit-box-orient: vertical;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .project-card img {
    max-width: 100%;
    border-radius: 8px;
    height: auto;
    width: 100%;
    margin-bottom: 0.5em;
  }

  .project-info{
    display: flex;
    flex-direction: column;
  }
  .project-info p strong {
    display: inline-block;
    min-width: 50%; 
    max-width: 15%;
  }
  .project-info p {
    margin: 0.5em 0; /* Reduces the space around the paragraphs */
  }
/* Vote Share Section */
.vote-share-section {
  bottom: 10px;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #ddd;
  margin-top: 10px;
  }

/* share button */

.share-button {
  border: none;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.3em;
}
.share-button svg{
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.share-button button {
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.share-button button:hover {
  background-color: #00597a;
  color: #fff;
}

.share-dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  width: -webkit-max-content;
  width: max-content; 
  z-index: 100; 
  display: flex; 
  opacity: 1; 
  visibility: visible; 
  flex-direction: row;
}

.share-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: center;
}

.share-dropdown-content a:hover {
  background-color: #ddd;
}

.share-dropdown-content img {
  width: 20px;
  height: 20px;
}
/* end share button */
/* Vote Count */
.vote-count {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1.3em;
}

.upvote {
  color: #0095d5;
  margin-left: 10px;
}

.downvote {
  color: #a45104;
  margin-right: 10px;
}

.upvote:hover {
  color: #33b4e3;
}

.downvote:hover {
  color: #f60505;
}

.vote-count span {
  margin-left: 10px;
}

@media screen and (max-width: 1112px) {
  .project-card {
    width: 90%; /* Adjust the width for smaller screens */
    margin: 16px auto; /* Center the card on smaller screens */
  }
}

@media screen and (min-width: 1112px) {
  .project-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
.project-list-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 1.4em;
    margin-bottom: 10%;
  }
  .project-list {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    justify-content: center;
    align-items: center;
  }
  /* Loading container styles */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

/* SVG styles, if needed, can be adjusted or expanded */
.svg-loading {
  margin: auto;
  background: none;
  display: block;
  width: 50px;
  height: 50px;
}
  @media screen and (max-width: 1112px) {
    .project-list-page {
      width: 100%;
      padding: 10px;
      margin: auto;
    }
  }
/* Add Project Form Styles */
.project-form-container{
width: 60%;
margin-bottom: 10%;

}
.add-project-form {
  font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .add-project-form input[type="text"],
  .add-project-form input[type="number"],
  .add-project-form input[type="date"],
  .add-project-form input[type="email"],
  .add-project-form select,
  .add-project-form button {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: 'Poppins', sans-serif;
  }
  .add-project-form select {
    width: 97%;
  }
  
  .add-project-form label {
    display: block;
    margin-bottom: 10px;
    padding-left: 10px;
    align-self: flex-start;
    font-family: 'Poppins', sans-serif;

  }
  
  .add-project-form button {
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    width: auto;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1.6em;
  }
  
  .add-project-form button:hover {
    background-color: #003d7a;
  }
  .add-project-form textarea {
    width: 95%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 100px; /* Adjust height as needed */
    font-family: 'Poppins', sans-serif;

  }
  
  .section.location {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 94.5%;
  }

  .section h3 {
    margin-top: 0;
  }
  @media screen and (max-width: 1112px) {
    .project-form-container {
      width: 95%;
      padding: 10px;
      margin: auto;
    }
  }
.faq-container {
    width: 60%;
    /* max-width: 800px; */
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10%;

  }
  .faq-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8em;
  }

  .faq-container h3 {
    font-size: 1.6em;
  }
  .faqs {
    list-style-type: none;
    padding: 0;
    font-size: 1.5em;
  }
  
  .faq {
    border-bottom: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
  }
  
  .faq:last-child {
    border-bottom: none;
  }
  .faq-question {
    color: #0056b3;
  }
  
  .faq-answer {
    display: none;
    padding-top: 10px;
  }
  
  .faq.active .faq-answer {
    display: block;
  }
  @media screen and (max-width: 1112px) {
    .faq-container {
      width: 95%;
      padding: 10px;
      margin: auto;
    }
  }
/* App.css */

body {
  font-family: 'Poppins', sans-serif;
}
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f4f4f4;
  /* background-image: radial-gradient(circle at center, rgb(216 216 216 / 80%), white); */
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px;
  background-color: #f4f4f4;
  text-align: center;
  box-shadow: 0 -2px 5px rgba(0,0,0,0.1);
}
.footer-contact-email a{
  margin-top: 15px;
  color: inherit; /* This makes the link use the text color of its parent, removing the default blue */
  text-decoration: none; /* This removes the underline */
  cursor: pointer;
}
.sidebar {
  position: fixed;
  right: 0;
  top: 0;
}

.error-message {
  color: red;
  font-size: 1.6em;
  margin-top: 15px;
}

@media (max-width: 1112px) {
  .sidebar {
    position: relative;
  }
  .footer {
    position: relative;
    width: auto;
  }
}

  
.dashboard-container {
    width: 70%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 10%;
}

.dashboard-user-info {
    background-color: #f9fafb;
    /* Light blue background */
    border-radius: 10px;
    padding-left: 2%;
    margin: 2%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
}

.dashboard-user-info p {
    font-style: italic;
    font-weight: bold;
    font-size: 1.8em;
    color: #056baf;
    margin: 10px 0;
}

.dashboard-user-info span {
    margin-left: 10px;
    /* Adjust this value as needed to create more space */
    font-size: 1.2em;
    font-weight: bold;
    color: #313131;
    font-style: normal;
    /* Dark blue for emphasis */
}

.dashboard-header {
    font-size: 2em;
    color: #00597a;
    margin-bottom: 20px;
    padding-top: 3%
}

.dashboard-table {
    width: 90%;
    border-collapse: collapse;
    margin: 20px 0;
    font: 1.4em 'Poppins', sans-serif;
}

.dashboard-table th,
.dashboard-table td {
    text-align: left;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.dashboard-table td a {
    color: #00597a;
    /* Dark blue to match your color scheme */
    text-decoration: none;
    /* Optional: Removes underline from links */
}

.dashboard-table td a:hover {
    text-decoration: underline;
    /* Optional: Adds underline on hover for better user interaction */
}

.dashboard-table th {
    background-color: #00597a;
    color: #fafafa;
}

.dashboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.dashboard-table td img {
    width: 20%;
    height: auto;
    border-radius: 5px;
    /* Rounded corners for images */
}

@media screen and (max-width: 1112px) {
    .dashboard-container {
        width: 95%;
        padding: 10px;
        margin: auto;
    }

    .dashboard-header {
        font-size: 1.5em;
        /* Adjust header font size for smaller screens */
        text-align: center;
        /* Center the header text */
        padding: 10px 0;
        /* Adjust padding to reduce space */
    }

    .dashboard-user-info {
        width: 100%;
        /* Make user info take full width */
        padding: 10px;
        /* Adjust padding for smaller screens */
        align-items: center;
        /* Align items in the center for smaller screens */
        text-align: center;
        /* Center text for user info */
    }

    .dashboard-user-info p {
        font-size: 1.2em;
        /* Adjust font size for smaller screens */
    }

    .dashboard-table {
        font-size: 0.8em;
        /* Reduce table font size for readability */
    }

    .dashboard-table td img {
        width: 50px;
        /* Adjust image width in table cells */
        height: auto;
        /* Keep aspect ratio */
    }

    .tab-buttons {
        flex-direction: column;
        /* Stack tab buttons vertically on small screens */
    }
}

.tab {
    margin-top: 20px;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
    width: 100%;
}

.tab-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.tab-button {
    background-color: #f9fafb;
    border: 1px solid #ddd;
    padding: 10px 20px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 5px;
    color: #056baf;
    font-size: 1.2em;
    font-family: 'Poppins', sans-serif;
}

.tab-button.active {
    background-color: #056baf;
    color: white;
}

.tab-tab-content {
    background-color: #f9fafb;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Styles for PendingProjects Component */
.pending-projects-container, .active-projects-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.pending-project , .active-project{
    background-color: white;
    padding: 15px;
    margin-bottom: 10px;
    width: 90%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
}

.tab-project-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
}

.project-info>div {
    margin-bottom: 5px;
}

.project-title,
.project-store,
.project-price,
.project-contact {
    font-size: 1em;
    color: #313131;
}

.project-image img {
    max-width: 400px;
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
}

.project-actions button {
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
}

.project-actions button:hover {
    opacity: 0.8;
}

.tab-error-message {
    color: red;
    margin-top: 10px;
}

.project-updated {
    background-color: #e0f7fa;
    /* A subtle color to indicate update, adjust as per your UI */
}

.tab-project-actions-reject {
    background-color: #f4b352;
    color: white;
}

.tab-project-actions-reject:hover {
    background-color: #e7900e;
    opacity: 0.8;
}

.tab-project-actions-approve {
    background-color: #4caf50;
    color: white;
}

.tab-project-actions-approve:hover {
    background-color: #3e8e41;
    opacity: 0.8;
}

/* Responsive design for smaller screens */
@media screen and (max-width: 1112px) {
    .pending-projects-container .pending-project {
        width: 100%;
    }
}
/* ProjectDetailPage.css */

.project-detail {
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 60%;
    font-size: 1.6em;
    margin-bottom: 10%;
  }
  .project-contact a{
    color: inherit; /* This makes the link use the text color of its parent, removing the default blue */
    text-decoration: none; /* This removes the underline */
    cursor: pointer;
  }
  .project-detail img {
    width: 50%;
    border-radius: 8px;
    display: block;
    margin: 0 auto;
  }
  .project-title {
    margin-bottom: 20px;
    color: #333;
    font-size: 1.4em;
  }
  
  .project-meta, .project-description, .project-contact, .project-stats, .project-status {
    margin-bottom: 20px;
  }
  .project-contact {
    margin-bottom: 40px;
  }
  .project-meta p, .project-description p, .project-contact p, .project-stats p, .project-status p {
    margin: 10px 0;
    line-height: 1.6;
  }
  .project-detail p strong {
    display: inline-block;
    min-width: 25%; 
    max-width: 50%;
  }
  strong {
    color: #0056b3;
  }
  .detail-vote-share-section {
    bottom: 10px;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid #ddd;
    margin-top: 10px;
    margin-bottom: 5%;
    padding-bottom: 50px;
    }
  @media screen and (max-width: 1112px) {
    .project-detail{
      width: 95%;
      padding: 10px;
      margin: auto;
    }
  }
.homepage {
  font-family: 'Poppins', sans-serif;
  color: #101010;
  width: 100%;
}
.content-and-image {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10%;
  width: 80%;
  margin: auto;
  padding-top: 3%;
}

.content-section {
  flex: 1 1;
  text-align: center;
}
.content-section h1{
  font-size: 2.4em;
}
.content-section p {
  font-size: 1.4em;
  font-style: italic;
}
.image-section {
  flex: 1 1;
  height: 600px;
  background-size: cover;
  background-position: center;
  background-image: url(/static/media/home.16d7b1a6.jpg);
  border-radius: 10px;
}

.key-points {
  padding: 12px 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; 
}

.key-points ul {
  list-style-type: none;
  padding: 0 20px;
}

.key-points li {
  font-size: 2em;
  line-height: 2;
  margin-bottom: 16px;
  position: relative; /* Prepares for custom bullet */
  padding-left: 40px; /* Makes space for custom bullet */
  text-align: left; /* Ensures text is aligned left */
  color: #101010; /* Adjust color to fit your design, if needed */
}

.key-points li::before {
  content: '•'; /* Simple bullet point */
  color: #a7a6a6; /* Gold color for bullet points for visibility */
  font-size: 2em; /* Larger bullet point size */
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(0%, -25%); /* Adjusts bullet position */
}

.action-links {
  padding: 10px 0 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-links a {
  background-color: #0057b5;
  border-radius: 15px;
  color: #e7e7e7;
  padding: 12px 5px;
  margin: 0 15px;
  text-decoration: none;
  font-size: 2.2em;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
  width: 180px;
}

.action-links a:hover {
  background-color: #0056b3;
}
@media screen and (max-width: 1112px) {
  .homepage{
    width: 95%;
    padding: 10px;
    margin:auto;
    color: #e2e5ea;
  }
  .hero-section {
    padding: 0px;
    }
  .key-points {
    width: 100%;
    padding: 0px;
  }
  .key-points li {
    font-size: 1.8em;
    color: #e2e5ea;
  } 
  .content-and-image {
    flex-direction: column;
    padding: 0px;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.7),rgba(0, 0, 0, 0.9)),
    url(/static/media/home.16d7b1a6.jpg) no-repeat center center/cover; 
    border-radius: 10px;
    width: 100%;
    font-size: 0.8em;
  }
  .image-section {
    background: none;
  }
  .action-links a{
    font-size: 1.8em;
    /* color: #e2e5ea; */
    /* background-color: #0056b3; */

    }
}
/* Updated AboutPage.css */

.about-container {
  width: 80%;
  margin: auto;
  padding: 40px;
  margin-bottom: 10%;
}
.about-container h1, .about-container h2 {
  color: #00597a;
}
.about-container h1{
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 3em;
}
.about-container h2{
  font-size: 2em;
  margin-top: 2em;
}

.about-section {
  display: flex;
  align-items: center; /* Ensures image is aligned with text vertically */
  justify-content: center; /* Centers the content horizontally */
  margin-bottom: 50px;
}

.about-section-image {
  display: flex;
  align-items: center; /* Center align the image within its container */
  justify-content: center; /* Center the image horizontally within its container */
}

.about-section-image img {
  max-width: 100%; /* Ensures the image is responsive and does not overflow its container */
  height: auto; /* Maintains the aspect ratio of the image */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.about-section-text, .about-section-image {
  flex: 1 1;
}

.about-section-text {
  padding: 0 20px;
}
.about-section-text h2 {
 margin: auto;
}
.about-section-text p {
  line-height: 1.6;
  text-align: justify;
  font-size: 1.6em;
}

.about-section-image img{
  width: 100%;
  height: auto;
}

.reverse {
  flex-direction: row-reverse;
}

@media screen and (max-width: 1112px) {
  .about-container {
    width: 95%;
    padding: 10px;
    margin:auto;
  }
  .about-section {
    flex-direction: column;
  }
  .reverse {
    flex-direction: column;
  }
}

